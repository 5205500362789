import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Template from '../../Components/Template';
import UserDialog from "../Message";
import Loader from "../../Components/Loader";

// globals
import {sectionType, sectionTypeName} from "../../Globals/Constants";

// models
import {Messages} from './../../Models';

// helpers
import {ls} from "../../Globals/Localstorage";

// styles
import './styles.css';

const MessagesScreen = () => {
    const [type, setType] = useState(sectionType.UNKNOWN);
    const [userData, setUserData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [selectedUserMessagesId, setSelectedUserMessagesId] = useState(null);
    const [userMessages, setUserMessages] = useState([]);

    useEffect(() => {
        const messagesGet = async () => {
            const data = await Messages.get(type);
            setUserData(data);
        };
        messagesGet();
    }, [type]);

    useEffect(() => {
        if (userData) {
            const foundMessage = userData.filter((msg) => msg.passportUserId);
            if (foundMessage) {
                setMessages(foundMessage);
            }
        }
    },[userData])

    const handleChange = (e) => setType(parseInt(e.target.value));


    useEffect(() => {
        if (selectedUserMessagesId !== null) {
            // Фильтруем сообщения по выбранному юзеру
            const filteredMessages = messages.filter(
                (message) => message.passportUserId === selectedUserMessagesId
            );
            // Устанавливаем сообщения юзера в состояние
            setUserMessages(filteredMessages);
        }
    }, [messages, selectedUserMessagesId]);

    const messageGroups = messages.reduce((groups, message) => {
        const { passportUserId, message: msg } = message;
        if (!groups[passportUserId]) {
            groups[passportUserId] = [];
        }
        groups[passportUserId].push(msg);
        return groups;
    }, {});

    const lastMessages = Object.values(
        messages.reduce((acc, cur) => {
            if (!acc[cur.passportUserId]) {
                acc[cur.passportUserId] = cur;
            } else if (acc[cur.passportUserId].createdAt < cur.createdAt) {
                acc[cur.passportUserId] = cur;
            }
            return acc;
        }, {})
    );

    return (
        <Template>
            <div className='messages-container'>
                <div className='messages__menu'>
                    <h3 className='messages__header'>Сообщения</h3>
                    <div className='messages__section'>
                        <span className='span'>Секция</span>
                        <select className='select section-select' defaultValue={type} onChange={handleChange}>
                            {sectionTypeName.map((v, i) => (
                                <option className='option' key={i} value={i}>{v}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <ul>
                        {   lastMessages.length > 0 ?
                            lastMessages.map((message) => (
                            <li key={message.passportUserId}
                                onClick={() => setSelectedUserMessagesId(message.passportUserId)}>
                                <Link  className='last__link' to={{
                                    pathname: '/dialog',
                                    state: { userId: message.passportUserId,
                                        messages: messageGroups[message.passportUserId],
                                        sectionType: message.sectionType,
                                        userFullName: message.userFullName,
                                        paymentId: message.paymentId,
                                        type: message.type,
                                        status: message.status,
                                        userPhone: message.userPhone}
                                    }} >
                                        <div className='last__messages'>
                                            <div className='last__messages__name'>{`User ${message.userFullName ?? message.passportUserId}`}</div>
                                            <div className='last__messages__item'>{message.message}</div>
                                        </div>
                                </Link>
                            </li>
                        )) : null}
                    </ul>
                    {selectedUserMessagesId !== null && (
                            <UserDialog
                            userId={selectedUserMessagesId}
                            messages={userMessages}
                            />
                    )}
                </div>
            </div>
        </Template>)
};

export default MessagesScreen;