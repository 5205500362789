import Http from '../../Globals/Http';

const get   = async (type)      => await Http.request(`messages/${type}`);

const add = async ({paymentId,
                    sectionType,
                    passportUserId,
                    userFullName,
                    userPhone,
                    supportUserId,
                    supportUserName,
                    message,
                    isAdmin,
                    type,
                    status})            => await Http.request('message',
            {   paymentId,
                        sectionType,
                        passportUserId,
                        userFullName,
                        userPhone,
                        supportUserId,
                        supportUserName,
                        message,
                        isAdmin,
                        type,
                        status
            });


export {
    get,
    add
}