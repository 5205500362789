const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? 'http://localhost:8059' : 'https://api.support.super-appz.ru';

const API = {
	url			: `${url}/api/v1/`,
	version		: '1.0.0'
};

const commonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const commonStatusName = ['Не активен','Активен'];

const userStatus = {
	ACTIVE		: 1,
	IN_ACTIVE	: 2,
	BAN			: 3,
	DELETED		: 4
};
const userStatusName = ['','Активен','Не активен','Заблокирован','Удален'];

const sectionType = {
	UNKNOWN		: 0,
	TAXI		: 1,
	FOOD		: 2,
	HOTELS		: 3,
	MARKETPLACE	: 4,
	DELIVERY	: 5,
	AVIA		: 6,
	BOOKING		: 7,
	MARKETS		: 8
};
const sectionTypeName = ['','Такси','Еда','Отели','Маркетплейс','Доставка','Билеты','Бронирования','Магазины']

export {
	API,
	commonStatus,
	commonStatusName,
	userStatus,
	userStatusName,
	sectionType,
	sectionTypeName
};