import React, {useEffect, useState} from 'react';
import { useLocation, useHistory} from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from "../../Components/Loader";

// styles
import './styles.css';

// models
import {Messages} from './../../Models';

// helpers
import {ls} from "../../Globals/Localstorage";

function UserDialog() {
    const [messages, setMessages] = useState([]);
    const [userMessages, setUserMessages] = useState([]);
    const [responseMsg, setResponseMsg] = useState("");
    const [createdAtMessage, setCreatedAtMessage] = useState([]);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const messagesGet = async () => {
            console.log(111111, location.state.sectionType)
            const messages = await Messages.get(location.state.sectionType);
            setMessages(messages);
            // switch (location.state.sectionType) {
            //     case 1:
            //         const taxi = await Messages.getTaxi();
            //         setMessages(taxi);
            //         break;
            //     case 2:
            //         const food = await Messages.getFood();
            //         setMessages(food);
            //         break;
            //     case 3:
            //         const hotels = await Messages.getHotels();
            //         setMessages(hotels);
            //         break;
            //     case 4:
            //         const market = await Messages.getMarket();
            //         setMessages(market);
            //         break;
            //     case 5:
            //         const delivery = await Messages.getDelivery();
            //         setMessages(delivery);
            //         break;
            //     case 6:
            //         const avia = await Messages.getAvia();
            //         setMessages(avia);
            //         break;
            //     case 7:
            //         const booking = await Messages.getBooking();
            //         setMessages(booking);
            //         break;
            //     case 0:
            //         const unknown = await Messages.getUnknown();
            //         setMessages(unknown);
            // }
        };

        // Запуск messagesGet при монтировании компонента
        messagesGet();

        setTimeout(messagesGet, 1);
        // Выполнение функции messagesGet каждую минуту
        const interval = setInterval(messagesGet, 60000);

        // Очистка интервала перед повторным вызовом useEffect
        return () => clearInterval(interval);
    }, []);



    useEffect(() => {
        const filteredMessages = messages.filter((msg) => msg.passportUserId === location.state.userId);
        setUserMessages(filteredMessages);
        const filteredData = userMessages.map(data => data.createdAt);
        setCreatedAtMessage(filteredData);

    }, [messages, location.state.userId]);

    const handleGoBack = () => {
        history.goBack();
    };

    const handleResponseSubmit = async (event) => {
        event.preventDefault();
        const newMessage = {
            "paymentId": location.state.paymentId,
            "sectionType" : location.state.sectionType,
            "passportUserId" : location.state.userId,
            "userFullName" : location.state.userFullName,
            "userPhone" : location.state.userPhone,
            "supportUserId" : location.state.supportUserId ?? "6419ecbec420683ba5c266d2",
            "supportUserName": location.state.supportUserName ?? "Admin",
            "message" : responseMsg,
            "isAdmin" : "true",
            "type" : location.state.type,
            "status": location.state.status,
        };
        await Messages.add(newMessage);
        setUserMessages(prevUserMessages => [...prevUserMessages, newMessage]);
        setResponseMsg(""); // Очистка поля ввода после отправки сообщения
    }

    const sendResponseMsg = (event) => {
        event.preventDefault();
        setResponseMsg((event.target.value));
    }

        // Разбиваем строку на массив дат
        const dates = createdAtMessage;

        // Создаем пустой массив для отформатированных дат
        const formattedDates = [];

        // Проходимся по всем датам
        dates.forEach(date => {
            // Создаем новый объект Date из строки
            const newDate = new Date(date);

            // Получаем компоненты даты
            const day = String(newDate.getDate()).padStart(2, '0');
            const month = String(newDate.getMonth() + 1).padStart(2, '0');
            const year = String(newDate.getFullYear());
            const hours = String(newDate.getHours()).padStart(2, '0');
            const minutes = String(newDate.getMinutes()).padStart(2, '0');

            // Форматируем дату и добавляем в массив отформатированных дат
            formattedDates.push(`${day}.${month}.${year} ${hours}:${minutes}`);
        });

    console.log(userMessages);

    return (
        <Template>
            <div>
                <button onClick={handleGoBack} className='back-button'></button>
                <div className='dialog__container'>
                    <h2 className='dialog__header'>User {location.state.userFullName ?? location.state.userId}</h2>
                    {userMessages.length > 0 ? (
                        <ul className="dialog__list">
                            {userMessages.map((message, index) => (
                                message.isAdmin ? (
                                    <div key={index} className="dialog__item admin">
                                        <p className='dialog__item__time' key={message._id}>{formattedDates[index]}</p>
                                        <p className='dialog__item__name' key={message._id + 100}>{message.supportUserName}</p>
                                        <li  key={index}>
                                            {message.message}
                                        </li>
                                    </div>
                                ) : (
                                    <div  className="dialog__item">
                                        <p className='dialog__item__time' key={message._id}>{formattedDates[index]}</p>
                                        <p className='dialog__item__name' key={message._id - 100}>{message.userFullName}</p>
                                        <li key={index}>
                                            {message.message}
                                        </li>
                                    </div>
                                )
                            ))}
                        </ul>
                    ) : (
                        <Loader />
                    )}
                    <div className='form'>
                        <form className='message-response'
                              onSubmit={handleResponseSubmit}>
                            <input className='message-response__text'
                                   type="text"
                                   value={responseMsg}
                                   onChange={(event) => sendResponseMsg(event)}
                                   placeholder='Напишите сообщение ...'/>
                            <button className='message-response__btn'>Отправить</button>
                        </form>
                    </div>
                </div>

            </div>
        </Template>
    );
}

export default UserDialog;